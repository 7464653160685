import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  breakpointQuery,
  standardColours,
  fontSize,
  standardTransition,
} from '../utilities';

const commonButtonStyles = () => {
  return css`
    display: inline-block;
    padding: 6px 24px;
    color: ${({ colour }) => (colour ? colour : standardColours.white)};
    ${fontSize(14)};
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    background-color: ${({ backgroundcolour }) =>
      backgroundcolour ? backgroundcolour : standardColours.darkGrey};
    border: 2px solid
      ${({ backgroundcolour }) =>
        backgroundcolour ? backgroundcolour : standardColours.darkGrey};
    border-radius: 8px;
    cursor: pointer;
    transition: ${standardTransition('color')},
      ${standardTransition('background-color')};

    &:hover {
      color: ${({ backgroundcolour }) =>
        backgroundcolour ? backgroundcolour : standardColours.darkGrey};
      background-color: ${standardColours.white};
    }

    ${breakpointQuery.small`
      padding: 8px 28px;
      ${fontSize(15)};
    `}

    ${breakpointQuery.large`
      padding: 10px 32px;
      ${fontSize(16)};
    `}
  `;
};

const StyledButtonLinkInternal = styled(Link)`
  ${commonButtonStyles()};
`;

const StyledButtonLinkExternal = styled.a`
  ${commonButtonStyles()};
`;

const ButtonLink = ({
  activeClassName,
  partiallyActive,
  children,
  ...props
}) => {
  const ButtonComponent = props.to
    ? StyledButtonLinkInternal
    : StyledButtonLinkExternal;

  return (
    <ButtonComponent
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      {...props}
    >
      {children}
    </ButtonComponent>
  );
};

export default ButtonLink;
