import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-left: 30px;
  padding-right: 30px;

  ${({ narrow }) => {
    if (narrow) {
      return `
        max-width: 860px;
      `;
    } else {
      return `
        max-width: 1260px;
      `;
    }
  }}
`;

const Container = ({ narrow, className, children }) => {
  return (
    <StyledContainer narrow={narrow} className={className}>
      {children}
    </StyledContainer>
  );
};

export default Container;
