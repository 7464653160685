import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { breakpointQuery, standardColours } from '../../utilities';
import Container from '../Container';

const Section = styled.div`
  padding-top: 38px;
  padding-bottom: 38px;
  font-size: 10px;
  background: ${standardColours.darkestGrey};
  color: ${standardColours.lighterGrey};

  ${breakpointQuery.smedium`
    padding-top: 46px;
    padding-bottom: 46px;
  `}
`;

const Navigation = styled.ul`
  ${breakpointQuery.mlarge`
    display: flex;
  `}
`;

const Information = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpointQuery.mlarge`
    flex-direction: row;
  `}
`;

const Copyright = styled.p`
  margin-bottom: auto;

  ${breakpointQuery.mlarge`
    margin-right: 8px;
  `}

  ${breakpointQuery.xlarge`
    margin-right: 50px;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NavigationItem = styled.li`
  margin-top: 10px;

  &:first-child {
    margin: 0;
  }

  ${breakpointQuery.mlarge`
    margin-top: 0;
    margin-left: 8px;
  `}

  ${breakpointQuery.xlarge`
    margin-left: 12px;
  `}
`;

const LowerFooter = ({ navigation, lowerFooterLinks }) => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Navigation>
            {navigation.map(({ id, text, url }) => (
              <NavigationItem key={`footer-${id}`}>
                <Link to={url}>{text}</Link>
              </NavigationItem>
            ))}
          </Navigation>
          <Information>
            <Copyright>&copy; {new Date().getFullYear()} Barcanet</Copyright>
            <Navigation>
              {lowerFooterLinks && (
                <>
                  {lowerFooterLinks.map(({ id, text, url }) => (
                    <NavigationItem key={id}>
                      <Link to={url}>{text}</Link>
                    </NavigationItem>
                  ))}
                </>
              )}
              <NavigationItem key="attach">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.attachdigital.co.uk/"
                >
                  Website by Attach Digital
                </a>
              </NavigationItem>
            </Navigation>
          </Information>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default LowerFooter;
