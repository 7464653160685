import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import {
  breakpointQuery,
  standardColours,
  brandColours,
  fontSize,
} from '../utilities';
import Subnavigation from './navigation/Subnavigation';

const StyledNavigation = styled.div`
  z-index: 30;

  ${breakpointQuery.mlarge`
    margin-left: 20px;
  `}
`;

const StyledNavigationToggle = styled.button`
  position: relative;
  display: block;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  ${breakpointQuery.mlarge`
    display: none;
  `};

  span {
    display: block;
    margin: 6px 0;
    height: 2px;
    width: 30px;
    background-color: ${brandColours.tertiary};

    ${breakpointQuery.small`
      margin-top: 8px;
      margin-bottom: 8px;
      height: 3px;
      width: 40px;
    `}
  }
`;

const StyledNavigationLinks = styled.nav`
  display: none;
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  background-color: ${brandColours.tertiary};

  ${breakpointQuery.small`
    top: 90px;
  `}

  ${breakpointQuery.mlarge`
    position: static;
    display: block;
    background: none;
  `}

  &.js-nav-open {
    display: block;
  }
`;

const StyledNavigationList = styled.ul`
  padding: 22px 0;
  list-style-type: none;

  ${breakpointQuery.mlarge`
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
  `}
`;

const StyledNavigationItem = styled.li`
  margin-top: 8px;

  ${breakpointQuery.mlarge`
    display: inline-block;
    margin-top: 0;
    margin-left: 20px;
  `}

  ${breakpointQuery.large`
    margin-left: 30px;
  `}

  ${breakpointQuery.xxlarge`
    margin-left: 40px;
  `}

  &:first-child {
    margin-top: 0;

    ${breakpointQuery.mlarge`
      margin-left: 0;
    `}
  }

  ${({ subnavigation }) => {
    if (subnavigation) {
      return breakpointQuery.mlarge`
        &:hover {

          ul {
            left: 0;
          }

          > a {
            position: relative;

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 50%;
              display: block;
              height: 0;
              width: 0;
              border: 12px solid transparent;
              border-bottom-color: ${standardColours.lighterGrey};
              transform: translateX(-50%);
            }
          }
        }
      `;
    }
  }}
`;

const StyledNavigationLink = styled(Link)`
  display: block;
  padding: 8px 30px;
  color: ${standardColours.white};
  ${fontSize(18)};

  ${({ alt }) => {
    if (alt) {
      return `
        color: ${standardColours.white};
      `;
    }
  }}

  ${breakpointQuery.small`
    ${fontSize(20)};
  `}

  ${breakpointQuery.mlarge`
    padding: 20px 6px;
    color: ${standardColours.darkerGrey};
    ${fontSize(16)};

    ${({ alt }) => {
      if (alt) {
        return `
        color: ${standardColours.white};
      `;
      }
    }}
  `}
`;

const Navigation = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      toggleNavigationClass: '',
    };
  }

  toggleNavigation = () => {
    this.setState({ active: !this.state.active }, () => {
      this.state.active
        ? this.setState({ toggleNavigationClass: 'js-nav-open' })
        : this.setState({ toggleNavigationClass: '' });
    });
  };

  render() {
    const { navigation, isCampaignPage, alt, ...props } = this.props;

    return (
      navigation.length > 0 && (
        <StyledNavigation {...props}>
          <StyledNavigationToggle
            aria-label="Open / Close Main Navigation"
            className={`${this.state.toggleNavigationClass}`}
            onClick={() => this.toggleNavigation()}
          >
            <span />
            <span />
            <span />
          </StyledNavigationToggle>
          <StyledNavigationLinks
            className={`${this.state.toggleNavigationClass}`}
          >
            <StyledNavigationList>
              {navigation.map(({ text, url }, id) => (
                <StyledNavigationItem
                  key={id}
                  subnavigation={
                    url === '/solutions/' ||
                    url === '/products-services/' ||
                    url === '/resources/'
                  }
                >
                  <StyledNavigationLink
                    {...(isCampaignPage
                      ? {
                          as: 'a',
                          href: `#${url}`,
                          onClick: () => this.toggleNavigation(),
                        }
                      : {
                          to: url,
                        })}
                    alt={alt}
                    activeClassName="current-page"
                    partiallyActive={true}
                  >
                    {text}
                  </StyledNavigationLink>
                  {(url === '/solutions/' ||
                    url === '/products-services/' ||
                    url === '/resources/') && <Subnavigation url={url} />}
                </StyledNavigationItem>
              ))}
            </StyledNavigationList>
          </StyledNavigationLinks>
        </StyledNavigation>
      )
    );
  }
};

export default Navigation;
