import React from 'react';
import styled from 'styled-components';
import { breakpointSizes, fluidFontSize } from '../utilities';

const StyledHeading = styled.h2`
  ${({ minFontSize, maxFontSize }) => {
    if (minFontSize && maxFontSize) {
      return fluidFontSize(
        minFontSize,
        maxFontSize,
        breakpointSizes.tiny,
        breakpointSizes.large
      );
    }
  }};
`;

const Heading = ({
  minFontSize = '25px',
  maxFontSize = '37px',
  className,
  children,
  ...props
}) => {
  return (
    <StyledHeading
      minFontSize={minFontSize}
      maxFontSize={maxFontSize}
      className={className}
      {...props}
    >
      {children}
    </StyledHeading>
  );
};

export default Heading;
