export const standardColours = {
  black: '#000',
  darkestGrey: '#1A1A1B',
  darkerGrey: '#233740',
  darkGrey: '#404040',
  grey: '#787878',
  lightGrey: '#D5D2D9',
  lighterGrey: '#F1F1F1',
  lightestGrey: '#FBFBFB',
  white: '#FFF',
  transparentBlack: value => {
    return `rgba(0, 0, 0, ${value})`;
  },
  transparentWhite: value => {
    return `rgba(255, 255, 255, ${value})`;
  },
  transparent: 'rgba(255, 255, 255, 0)',
};

export const brandColours = {
  primary: '#806EB2',
  secondary: '#3F2387',
  tertiary: '#24CCFD',
  quaternary: '#F3765F',
  quinary: '#7BC192',
  senary: '#F2EEFF',
  septenary: '#FDBA57',
  octonary: '#EFFAFE',
};
