import React from 'react';
import { StaticQuery, graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import {
  breakpointQuery,
  standardColours,
  fontWeights,
  fontSize,
} from '../../utilities';
import { ButtonInput } from '../FormFields';

const NewsAndInsightsWrap = styled.div`
  padding-top: 10px;
  padding-bottom: 30px;

  ${breakpointQuery.mlarge`
    padding-top: 30px;
    padding-bottom: 50px;
  `}
`;

const NewsAndInsightsInner = styled.div`
  ${breakpointQuery.mlarge`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const Form = styled.form`
  position: relative;

  ${breakpointQuery.mlarge`
    flex-basis: 48%;
  `}
`;

const Input = styled.input`
  width: 100%;
  height: 64px;
  padding-left: 20px;
  padding-right: 125px;
  border-radius: 4px;
  background: ${standardColours.transparentWhite(0.1)};
  border: 0;
  ${fontSize(14)}
  font-weight: ${fontWeights.light};
  color: ${standardColours.white};

  &::-webkit-input-placeholder {
    color: ${standardColours.white};
  }

  &:-ms-input-placeholder {
    color: ${standardColours.white};
  }

  &::placeholder {
    opacity: 1;
    color: ${standardColours.white};
  }
`;

const FormButton = styled(ButtonInput)`
  margin-top: 15px;
  letter-spacing: 1px;

  ${breakpointQuery.small`
    position: absolute;
    top: 50%;
    margin-top: -25px;
    right: 8px;
    width: auto;
  `}

  ${breakpointQuery.mlarge`
    padding-left: 28px;
    padding-right: 28px;
  `}
`;

const Text = styled.p`
  margin-bottom: 18px;
  ${fontSize(13)}
  font-weight: ${fontWeights.light};
  line-height: 1.7;

  ${breakpointQuery.mlarge`
    margin: 0;
    flex-basis: 38%;
  `}
`;

const NewsAndInsightsHeading = styled.h3`
  margin-bottom: 22px;
  ${fontSize(18)}
  font-weight: ${fontWeights.bold};

  ${breakpointQuery.mlarge`
    margin-bottom: 10px;
  `}
`;

const NewsAndInsights = ({
  data: {
    datoCmsContact: { emailExclusionItems },
  },
  newsletterText,
}) => {
  const hubspotutk =
    typeof document !== 'undefined'
      ? document.cookie.replace(
          /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
          '$1'
        )
      : '';

  const handleSubmit = e => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    const emailAddress = formData.get('email');

    if (
      !emailExclusionItems.find(({ email }) => emailAddress.includes(email))
    ) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          navigate('/newsletter-thanks/');
        })
        .catch(error => console.log(error));
    }
  };

  return (
    <NewsAndInsightsWrap>
      <div>
        <NewsAndInsightsHeading>News &amp; Insights</NewsAndInsightsHeading>
      </div>
      <NewsAndInsightsInner>
        <Text>{newsletterText}</Text>
        <Form
          id="news-insight"
          name="news-insight"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
          onSubmit={e => handleSubmit(e)}
        >
          <input type="hidden" name="form-name" value="news-insight" />
          <Input
            type="email"
            name="email"
            placeholder="Enter your email address"
            required
          />
          <input type="hidden" name="hubspotutk" value={hubspotutk} />
          <FormButton type="submit" value="Sign up" />
        </Form>
      </NewsAndInsightsInner>
    </NewsAndInsightsWrap>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query NewsAndInsightsQuery {
        datoCmsContact {
          emailExclusionItems {
            email
          }
        }
      }
    `}
    render={data => <NewsAndInsights data={data} {...props} />}
  />
);
