import styled, { css } from 'styled-components';
import {
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
} from '../utilities';

export const commonFormStyles = () => {
  return css`
    height: 50px;
    width: 100%;
    display: block;
    padding: 10px 18px;
    font-weight: ${fontWeights.light};
    ${fontSize(16)};
    border-radius: 10px;
    background-color: ${standardColours.white};
    border: 0;
  `;
};

export const ButtonInput = styled.input`
  ${commonFormStyles()}
  letter-spacing: 1px;
  color: ${standardColours.white};
  font-weight: ${fontWeights.bold};
  background-color: ${brandColours.tertiary};
  border: none;
  cursor: pointer;
`;
