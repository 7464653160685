import React, { useContext } from 'react';
import { StaticQuery, graphql, navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  breakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../utilities';
import { commonFormStyles, ButtonInput } from './FormFields';
import SectionHeader from './SectionHeader';
import ButtonLink from './ButtonLink';
import { SiteContext } from '../context/SiteContext';
import quoteMarks from '../images/quote-marks.svg';

const StyledButtonWrapper = styled.div`
  order: 3;

  ${({ toggleButton }) => {
    if (toggleButton) {
      return css`
        opacity: 0;
        pointer-events: none;
      `;
    }
  }}
`;

const StyledPopupContact = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  height: 100%;
  width: 100%;
  background-color: ${standardColours.transparentBlack(0.5)};

  ${({ isActive }) => {
    if (!isActive) {
      return `
        ${visuallyHidden()}
      `;
    }
  }}
`;

const StyledPopupContactInner = styled.div`
  position: relative;
  padding: 30px;
  width: 100%;
  max-width: 760px;
  background-color: ${standardColours.white};
  border-radius: 20px;

  ${breakpointQuery.small`
    padding: 40px;
  `}

  ${breakpointQuery.large`
    padding: 50px;
  `}
`;

const StyledPopupContactClose = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  ${fontSize(30)};
  background: none;
  border: none;
  cursor: pointer;
`;

const StyledPopupContactHeader = styled(SectionHeader)`
  margin-bottom: 30px;

  ${breakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${breakpointQuery.large`
    margin-bottom: 50px;
  `}
`;

const StyledPopupContactContent = styled.div`
  display: grid;
  gap: 40px;

  ${breakpointQuery.small`
    gap: 50px;
  `}

  ${breakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  `}
`;

const StyledPopupContactForm = styled.form`
  display: grid;
  gap: 12px;

  ${breakpointQuery.smedium`
    grid-column: 2;
    grid-row: 1;
  `}
`;

const StyledPopupContactLabel = styled.label`
  ${({ hide }) => {
    if (hide) {
      return `
        ${visuallyHidden()}
      `;
    } else {
      return `
        border-bottom: 1px solid ${standardColours.lightGrey};
      `;
    }
  }}
`;

const StyledPopupContactLabelText = styled.span`
  margin-left: 8px;
  ${fontSize(12)};

  ${({ hide }) => {
    if (hide) {
      return `
        ${visuallyHidden()}
      `;
    }
  }}
`;

const StyledPopupContactInput = styled.input`
  ${commonFormStyles()};
  padding: 10px;
  height: 40px;
  border-radius: 0;

  &::-webkit-input-placeholder {
    color: ${standardColours.darkerGrey};
  }

  &:-ms-input-placeholder {
    color: ${standardColours.darkerGrey};
  }

  &::placeholder {
    color: ${standardColours.darkerGrey};
  }
`;

const StyledPopupContactInputButton = styled(ButtonInput)`
  margin-top: 10px;
  text-transform: uppercase;
`;

const StyledPopupContactTestimonialLogos = styled.div``;

const StyledPopupContactTestimonial = styled.blockquote`
  margin: 0;
  padding-top: 40px;
  text-align: center;
  background: url(${quoteMarks}) no-repeat top center / 30px 22px;
`;

const StyledPopupContactTestimonialQuote = styled.p`
  line-height: 1.5;

  ${breakpointQuery.large`
    ${fontSize(17)};
  `}
`;

const StyledPopupContactTestimonialAuthor = styled.footer`
  margin-top: 15px;
  font-weight: ${fontWeights.bold};
`;

const StyledPopupContactLogos = styled.div`
  margin-top: 25px;

  &:before {
    content: '';
    display: block;
    margin: 0 auto 25px;
    height: 2px;
    width: 80%;
    max-width: 280px;
    background-color: ${brandColours.tertiary};
  }
`;

const StyledPopupContactLogosText = styled.p`
  margin-bottom: 15px;
  font-weight: ${fontWeights.regular};
  ${fontSize(14)};
  font-style: italic;
  text-align: center;
`;

const StyledPopupContactLogosItems = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  ${breakpointQuery.small`
    gap: 25px;
  `}

  ${breakpointQuery.large`
    gap: 30px;
  `}
`;

const StyledPopupContactLogosItem = styled.img`
  display: block;
  margin: auto;
  height: 30px;
  width: 100%;
  max-width: 90px;
`;

export const PopupContactButton = ({ buttonText, toggleButton, ...props }) => {
  const { setPopupContactActive } = useContext(SiteContext);
  return (
    <StyledButtonWrapper toggleButton={toggleButton}>
      <ButtonLink
        as="button"
        backgroundcolour={brandColours.quaternary}
        {...props}
        onClick={() => setPopupContactActive(true)}
      >
        {buttonText ? buttonText : 'Get in touch'}
      </ButtonLink>
    </StyledButtonWrapper>
  );
};

const PopupContact = ({
  data: {
    datoCmsContact: {
      popupHeading: headingInitial,
      popupTestimonialQuote: testimonialQuoteInitial,
      popupTestimonialAuthor: testimonialAuthorInitial,
      popupClientLogosText: clientLogosText,
      popupClientLogos: clientLogosInitial,
      emailExclusionItems,
    },
  },
  buttonText,
  heading: headingOverride,
  testimonialQuote: testimonialQuoteOverride,
  testimonialAuthor: testimonialAuthorOverride,
  clientLogos: clientLogosOverride,
  thanksPageSlug: thanksPageSlugOverride,
}) => {
  const { isPopupContactActive, setPopupContactActive } = useContext(
    SiteContext
  );

  const heading = headingOverride || headingInitial;
  const testimonialQuote = testimonialQuoteOverride || testimonialQuoteInitial;
  const testimonialAuthor =
    testimonialAuthorOverride || testimonialAuthorInitial;
  const clientLogos =
    clientLogosOverride && clientLogosOverride.length > 0
      ? clientLogosOverride
      : clientLogosInitial;

  const handleSubmit = e => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);

    const emailAddress = formData.get('email');

    if (
      !emailExclusionItems.find(({ email }) => emailAddress.includes(email))
    ) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          setPopupContactActive(false);
          navigate(
            thanksPageSlugOverride
              ? `/${thanksPageSlugOverride}/`
              : '/demo-thanks/'
          );
        })
        .catch(error => console.log(error));
    }
  };

  return (
    <StyledPopupContact isActive={isPopupContactActive}>
      <StyledPopupContactInner>
        <StyledPopupContactClose
          title="Close"
          aria-label="Close"
          onClick={() => setPopupContactActive(false)}
        >
          ✕
        </StyledPopupContactClose>
        <StyledPopupContactHeader heading={heading} />
        <StyledPopupContactContent>
          <StyledPopupContactForm
            id="popup-contact-form"
            name="popup-contact"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={e => handleSubmit(e)}
          >
            <input type="hidden" name="form-name" value="popup-contact" />
            <StyledPopupContactLabel>
              <StyledPopupContactLabelText hide="true">
                Name
              </StyledPopupContactLabelText>
              <StyledPopupContactInput
                type="text"
                name="name"
                placeholder="Name"
                required
              />
            </StyledPopupContactLabel>
            <StyledPopupContactLabel>
              <StyledPopupContactLabelText hide="true">
                Work email
              </StyledPopupContactLabelText>
              <StyledPopupContactInput
                type="email"
                name="email"
                placeholder="Work email"
                required
              />
            </StyledPopupContactLabel>
            <StyledPopupContactLabel>
              <StyledPopupContactLabelText hide="true">
                Phone number
              </StyledPopupContactLabelText>
              <StyledPopupContactInput
                type="tel"
                name="phone"
                placeholder="Phone number"
                required
              />
            </StyledPopupContactLabel>
            <StyledPopupContactLabel hide="true">
              <StyledPopupContactLabelText>
                Don't fill this out if you're human
              </StyledPopupContactLabelText>
              <input name="bot-field" />
            </StyledPopupContactLabel>
            <StyledPopupContactInputButton type="submit" value="Submit" />
          </StyledPopupContactForm>
          <StyledPopupContactTestimonialLogos>
            <StyledPopupContactTestimonial>
              <StyledPopupContactTestimonialQuote>
                {testimonialQuote}
              </StyledPopupContactTestimonialQuote>
              {testimonialAuthor && (
                <StyledPopupContactTestimonialAuthor>
                  {testimonialAuthor}
                </StyledPopupContactTestimonialAuthor>
              )}
            </StyledPopupContactTestimonial>
            <StyledPopupContactLogos>
              {clientLogosText && (
                <StyledPopupContactLogosText>
                  {clientLogosText}
                </StyledPopupContactLogosText>
              )}
              <StyledPopupContactLogosItems>
                {clientLogos.map(({ url, alt }, id) => (
                  <StyledPopupContactLogosItem
                    key={id}
                    src={url}
                    alt={alt}
                    loading="lazy"
                  />
                ))}
              </StyledPopupContactLogosItems>
            </StyledPopupContactLogos>
          </StyledPopupContactTestimonialLogos>
        </StyledPopupContactContent>
      </StyledPopupContactInner>
    </StyledPopupContact>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query PopupContactQuery {
        datoCmsContact {
          popupHeading
          popupTestimonialQuote
          popupTestimonialAuthor
          popupClientLogosText
          popupClientLogos {
            url
            alt
          }
          emailExclusionItems {
            email
          }
        }
      }
    `}
    render={data => (
      <>
        <PopupContact data={data} {...props} />
      </>
    )}
  />
);
