import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { breakpointQuery, fontSize, standardColours } from '../utilities';
import Logo from '../images/logo.svg';
import Navigation from './Navigation';
import { PopupContactButton } from './PopupContact';

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 15px;
  padding: 20px 30px;
  background-color: ${standardColours.white};
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 10px 0;
  z-index: 20;

  ${breakpointQuery.mlarge`
    align-items: center;
  `}

  ${breakpointQuery.xxxlarge`
    padding-right: 40px;
    padding-left: 40px;
  `}
`;

const StyledHeaderLogo = styled(Link)`
  max-width: 96px;

  ${breakpointQuery.small`
    max-width: 120px;
  `}

  ${breakpointQuery.mlarge`
    order: 1;
  `}
`;

const StyledHeaderButton = styled(PopupContactButton)`
  ${breakpointQuery.mlarge`
    order: 3;
  `}

  ${breakpointQuery.large`
    padding: 12px 36px;
    ${fontSize(17)};
  `}

  ${breakpointQuery.xlarge`
    padding: 14px 40px;
    ${fontSize(18)};
  `}
`;

const StyledHeaderNavigation = styled(Navigation)`
  order: 3;

  ${breakpointQuery.mlarge`
    order: 2;
  `}
`;

const Header = ({
  navigation,
  isCampaignPage,
  anchorLinks,
  removeCampaignAnchorNav,
  buttonText,
  toggleButton,
}) => {
  const removeNav = isCampaignPage && removeCampaignAnchorNav ? true : false;
  return (
    <StyledHeader>
      <StyledHeaderLogo {...(isCampaignPage ? { as: 'div' } : { to: '/' })}>
        <img src={Logo} loading="lazy" alt="Barcanet Logo" />
      </StyledHeaderLogo>
      <StyledHeaderButton buttonText={buttonText} toggleButton={toggleButton} />
      {!removeNav && (
        <StyledHeaderNavigation
          navigation={anchorLinks || navigation}
          isCampaignPage={isCampaignPage}
        />
      )}
    </StyledHeader>
  );
};

export default Header;
