import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { breakpointQuery, standardColours, fontSize } from '../utilities';
import Container from './Container';
import Logos from './footer/Logos';
import NewsAndInsights from './footer/NewsAndInsights';
import ContactInformation from './footer/ContactInformation';
import LowerFooter from './footer/LowerFooter';

const StyledFooterUpper = styled.div`
  padding: 40px 0;
  background: ${standardColours.darkGrey};
  color: ${standardColours.white};

  ${breakpointQuery.small`
    padding-top: 50px;
    padding-bottom: 50px;
  `}

  ${breakpointQuery.large`
    padding-top: 60px;
    padding-bottom: 60px;
  `}
`;

const StyledText = styled.p`
  margin-top: 30px;
  ${fontSize(10)};

  ${breakpointQuery.mlarge`
    margin-top: 40px;
  `}
`;

const Footer = ({
  navigation,
  isCampaignPage,
  data: {
    datoCmsSitewide: {
      recognitions,
      associations,
      newsletterText,
      phoneNumber,
      email,
      address,
      twitterProfileUrl,
      linkedinProfileUrl,
      disclaimer,
      lowerFooterLinks,
      footerQuickLinks,
    },
  },
}) => {
  return (
    <footer id="footer">
      <StyledFooterUpper>
        <Container>
          <Logos heading="Recognitions" items={recognitions} />
          <Logos heading="Associations" items={associations} />
          {!isCampaignPage && (
            <>
              <NewsAndInsights newsletterText={newsletterText} />
              <ContactInformation
                phoneNumber={phoneNumber}
                email={email}
                address={address}
                footerQuickLinks={footerQuickLinks}
                twitterProfileUrl={twitterProfileUrl}
                linkedinProfileUrl={linkedinProfileUrl}
              />
              {disclaimer && <StyledText>{disclaimer}</StyledText>}
            </>
          )}
        </Container>
      </StyledFooterUpper>
      {!isCampaignPage && (
        <LowerFooter
          navigation={navigation}
          lowerFooterLinks={lowerFooterLinks}
        />
      )}
    </footer>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        datoCmsSitewide {
          recognitions {
            url
            alt
            originalId
          }
          associations {
            url
            alt
            originalId
          }
          newsletterText
          footerQuickLinks {
            id
            text
            url
          }
          phoneNumber
          email
          address
          twitterProfileUrl
          linkedinProfileUrl
          disclaimer
          lowerFooterLinks {
            id
            text
            url
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
);
