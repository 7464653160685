import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import Twitter from '../../images/twitter.svg';
import LinkedIn from '../../images/linkedin.svg';
import { breakpointQuery, fontSize, fontWeights } from '../../utilities';

const ContactHeading = styled.h3`
  margin-bottom: 22px;
  ${fontSize(18)}
  font-weight: ${fontWeights.bold};

  ${breakpointQuery.mlarge`
    margin-bottom: 28px;
  `}
`;

const sharedContactStyles = css`
  margin-top: 14px;
  ${fontSize(16)};
  font-weight: ${fontWeights.light};
  line-height: 1.8;

  &:first-child {
    margin-top: 0;
  }
`;

const ContactInformationWrap = styled.div`
  padding-top: 20px;

  ${breakpointQuery.tiny`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 10px;
  `}

  ${breakpointQuery.mlarge`
    grid-template-columns: repeat(4, 1fr);
    column-gap: 80px;
    padding-top: 52px;
  `}
`;

const ContactSection = styled.div`
  padding: 20px 0;

  ${breakpointQuery.mlarge`
    padding: 0;
  `}
`;

const SocialList = styled.ul`
  display: flex;

  li {
    margin-left: 30px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const ContactItem = styled.p`
  ${sharedContactStyles};
  white-space: pre;
`;

const ContactListItem = styled.li`
  ${sharedContactStyles};
`;

export const GetInTouch = (phoneNumber, email) => (
  <>
    <ContactHeading>Get in touch</ContactHeading>
    <ContactItem>
      <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
    </ContactItem>
    <ContactItem>
      <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
        {email}
      </a>
    </ContactItem>
  </>
);

export const FindUs = address => (
  <>
    <ContactHeading>Find Us</ContactHeading>
    <ContactItem>{address}</ContactItem>
  </>
);

const ContactInformation = ({
  phoneNumber,
  email,
  address,
  twitterProfileUrl,
  linkedinProfileUrl,
  footerQuickLinks,
}) => {
  return (
    <ContactInformationWrap>
      <ContactSection>
        {footerQuickLinks && (
          <>
            <ContactHeading>Explore</ContactHeading>
            <ul>
              {footerQuickLinks.map(({ id, text, url }) => (
                <ContactListItem key={id}>
                  <Link to={url}>{text}</Link>
                </ContactListItem>
              ))}
            </ul>
          </>
        )}
      </ContactSection>
      <ContactSection>{GetInTouch(phoneNumber, email)}</ContactSection>
      <ContactSection>{FindUs(address)}</ContactSection>
      {(twitterProfileUrl || linkedinProfileUrl) && (
        <ContactSection>
          <ContactHeading>Social</ContactHeading>
          <SocialList>
            {twitterProfileUrl && (
              <li key="twitter">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={twitterProfileUrl}
                >
                  <img src={Twitter} loading="lazy" alt="Twitter" />
                </a>
              </li>
            )}
            {linkedinProfileUrl && (
              <li key="linkedin">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={linkedinProfileUrl}
                >
                  <img src={LinkedIn} loading="lazy" alt="LinkedIn" />
                </a>
              </li>
            )}
          </SocialList>
        </ContactSection>
      )}
    </ContactInformationWrap>
  );
};

export default ContactInformation;
