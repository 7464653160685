import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const Animation = ({ children }) => {
  return (
    <ScrollAnimation offset={100} animateIn="fade" animateOnce={true}>
      {children}
    </ScrollAnimation>
  );
};

export default Animation;
