import React from 'react';
import styled, { css } from 'styled-components';
import { breakpointQuery, brandColours, standardColours } from '../utilities';
import Container from './Container';
import Heading from './Heading';
import ButtonLink from './ButtonLink';

const StyledSectionHeader = styled.header``;

const StyledSectionHeaderInner = styled.div`
  ${breakpointQuery.medium`
    display: flex;
  `};

  ${({ hasContent }) => {
    if (!hasContent) {
      return css`
        justify-content: center;
      `;
    }
  }}
`;

const StyledSectionHeaderHeading = styled(Heading)`
  margin-bottom: 15px;

  ${breakpointQuery.small`
    margin-bottom: 20px;
  `};

  ${breakpointQuery.large`
    margin-bottom: 0;
  `};

  &:before {
    content: '';
    display: block;
    margin-bottom: 20px;
    height: 4px;
    background-color: ${brandColours.tertiary};

    ${breakpointQuery.small`
      margin-bottom: 25px;
    `};

    ${breakpointQuery.large`
      margin-bottom: 30px;
    `};
  }

  ${({ hasContent }) => {
    if (hasContent) {
      return css`
        ${breakpointQuery.medium`
          padding-right: 12%;
          width: 50%;
        `};
      `;
    } else {
      return css`
        width: 100%;
        text-align: center;

        ${breakpointQuery.medium`
          max-width: 620px;
        `}

        &:before {
          margin-right: auto;
          margin-left: auto;
          max-width: 360px;
        }
      `;
    }
  }}

  ${({ whiteLine }) => {
    if (whiteLine) {
      return css`
        &:before {
          background-color: ${standardColours.white};
        }
      `;
    }
  }}
`;

const StyledSectionHeaderContent = styled.div`
  ${breakpointQuery.medium`
    display: flex;
    flex-direction: column;
    width: 50%;
  `};
`;

const StyledSectionHeaderText = styled.p`
  line-height: 1.7;
`;

const StyledSectionHeaderButton = styled(ButtonLink)`
  margin: 10px 0;
  align-self: flex-end;
`;

const SectionHeader = ({
  heading,
  text,
  linkText,
  linkUrl,
  whiteLine,
  ...props
}) => {
  const hasContent = text || (linkText && linkUrl);

  return (
    <StyledSectionHeader {...props}>
      <Container>
        <StyledSectionHeaderInner hasContent={hasContent}>
          <StyledSectionHeaderHeading
            hasContent={hasContent}
            whiteLine={whiteLine}
          >
            {heading}
          </StyledSectionHeaderHeading>
          {hasContent && (
            <StyledSectionHeaderContent>
              {text && (
                <StyledSectionHeaderText>{text}</StyledSectionHeaderText>
              )}
              {linkText && linkUrl && (
                <StyledSectionHeaderButton
                  to={linkUrl}
                  backgroundcolour={brandColours.primary}
                >
                  {linkText}
                </StyledSectionHeaderButton>
              )}
            </StyledSectionHeaderContent>
          )}
        </StyledSectionHeaderInner>
      </Container>
    </StyledSectionHeader>
  );
};

export default SectionHeader;
