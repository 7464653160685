import { css } from 'styled-components';

export const breakpointSizes = {
  tiny: '400px',
  tsmall: '520px',
  small: '620px',
  smedium: '768px',
  medium: '870px',
  mlarge: '980px',
  large: '1120px',
  xlarge: '1260px',
  xxlarge: '1400px',
  xxxlarge: '1600px',
  xxxxlarge: '2000px',
};

export const breakpointQuery = Object.keys(breakpointSizes).reduce(
  (acc, size) => {
    acc[size] = (...args) => css`
      @media (min-width: ${breakpointSizes[size]}) {
        ${css(...args)}
      }
    `;
    return acc;
  },
  {}
);
