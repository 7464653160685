import { css } from 'styled-components'

export const fontSize = sizeValue => {
  return css`
    font-size: ${sizeValue}px;
    font-size: ${sizeValue / 10}rem;
  `
}

export const fluidFontSize = (minFontSize, maxFontSize, minVw, maxVw) => {
  return css`
    font-size: ${minFontSize};

    @media screen and (min-width: ${minVw}) {
      font-size: calc(
        ${minFontSize} + (${parseInt(maxFontSize)} - ${parseInt(minFontSize)}) *
          ((100vw - ${minVw}) / (${parseInt(maxVw)} - ${parseInt(minVw)}))
      );
    }

    @media screen and (min-width: ${maxVw}) {
      font-size: ${maxFontSize};
    }
  `
}
