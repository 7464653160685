import { css } from 'styled-components';
import { breakpointQuery } from '.';

export const sectionMargins = () => {
  return css`
    margin: 60px 0;

    ${breakpointQuery.tiny`
      margin-top: 70px;
      margin-bottom: 70px;
    `}

    ${breakpointQuery.small`
      margin-top: 80px;
      margin-bottom: 80px;
    `}

    ${breakpointQuery.medium`
      margin-top: 90px;
      margin-bottom: 90px;
    `}

    ${breakpointQuery.large`
      margin-top: 100px;
      margin-bottom: 100px;
    `}

    ${breakpointQuery.xxlarge`
      margin-top: 110px;
      margin-bottom: 110px;
    `}

    ${breakpointQuery.xxxxlarge`
      margin-top: 120px;
      margin-bottom: 120px;
    `}
  `;
};
