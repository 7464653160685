import React from 'react';
import styled from 'styled-components';
import { breakpointQuery, fontSize, fontWeights } from '../../utilities';

const StyledLogos = styled.div`
  padding-bottom: 20px;

  ${breakpointQuery.mlarge`
    display: flex;
    align-items: center;
    padding-bottom: 30px;
  `}

  ${breakpointQuery.xlarge`
    padding-bottom: 40px;
  `}
`;

const StyledLogosHeading = styled.h3`
  margin-bottom: 18px;
  ${fontSize(18)}
  font-weight: ${fontWeights.bold};
`;

const StyledLogosList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const StyledLogosImage = styled.img`
  max-height: 60px;
  max-width: 260px;
  margin-bottom: 18px;
  margin-right: 10px;

  ${breakpointQuery.tiny`
    margin-right: 20px;
  `}

  ${breakpointQuery.tsmall`
    margin-right: 40px;
  `};

  ${breakpointQuery.mlarge`
    margin-left: 50px;
    margin-right: 0;
    margin-bottom: 0;
  `}

  ${breakpointQuery.xlarge`
    margin-left: 60px;
  `}
`;

const Logos = ({ heading, items }) => {
  return (
    items.length > 0 && (
      <StyledLogos>
        <StyledLogosHeading>{heading}</StyledLogosHeading>
        <StyledLogosList>
          {items.map(item => (
            <li key={item.originalId}>
              <StyledLogosImage
                key={item.id}
                src={item.url}
                loading="lazy"
                alt={item.alt}
              />
            </li>
          ))}
        </StyledLogosList>
      </StyledLogos>
    )
  );
};

export default Logos;
