import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GlobalStyle from './Global';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Header from './Header';
import Footer from './Footer';
import PopupContact from './PopupContact';

const Layout = ({
  noIndex,
  isCampaignPage,
  anchorLinks,
  data: {
    datoCmsSitewide: { navigation },
  },
  removeCampaignAnchorNav,
  popupContactOverrides,
  buttonText,
  children,
  toggleButton,
}) => (
  <>
    <GlobalStyle />
    <HelmetDatoCms>
      <html lang="en" />
      {(noIndex || isCampaignPage) && (
        <meta name="robots" content="noindex, follow" />
      )}
    </HelmetDatoCms>
    <Header
      navigation={navigation}
      isCampaignPage={isCampaignPage}
      anchorLinks={anchorLinks}
      removeCampaignAnchorNav={removeCampaignAnchorNav}
      buttonText={buttonText}
      toggleButton={toggleButton}
    />
    {children}
    <Footer navigation={navigation} isCampaignPage={isCampaignPage} />
    <PopupContact {...popupContactOverrides} />
  </>
);

export default props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        datoCmsSitewide {
          navigation {
            id
            text
            url
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);
