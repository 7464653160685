import { css } from 'styled-components';
import { breakpointQuery } from '.';

export const sectionPaddings = () => {
  return css`
    padding: 60px 0;

    ${breakpointQuery.tiny`
      padding-top: 70px;
      padding-bottom: 70px;
    `}

    ${breakpointQuery.small`
      padding-top: 80px;
      padding-bottom: 80px;
    `}

    ${breakpointQuery.medium`
      padding-top: 90px;
      padding-bottom: 90px;
    `}

    ${breakpointQuery.large`
      padding-top: 100px;
      padding-bottom: 100px;
    `}

    ${breakpointQuery.xxlarge`
      padding-top: 110px;
      padding-bottom: 110px;
    `}

    ${breakpointQuery.xxxxlarge`
      padding-top: 120px;
      padding-bottom: 120px;
    `}
  `;
};
