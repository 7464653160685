import { createGlobalStyle } from 'styled-components';
import {
  standardColours,
  brandFonts,
  fontWeights,
  fontSize,
  breakpointQuery,
} from '../utilities';

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    overflow-x: hidden;
    font-size: 62.5%;
    scroll-padding-top: 80px;
    -webkit-font-smoothing: antialiased;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;

    ${breakpointQuery.small`
      scroll-padding-top: 90px;
    `}

    ${breakpointQuery.mlarge`
      scroll-padding-top: 100px;
    `}
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    color: ${standardColours.darkerGrey};
    font-family: ${brandFonts.primary};
    font-weight: ${fontWeights.light};
    ${fontSize(16)}
    line-height: 1.3;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    font-weight: ${fontWeights.light};
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  img {
    height: auto;
    vertical-align: middle;
    max-width: 100%;
    border: 0;
    -ms-interpolation-mode: bicubic;
  }

  textarea {
    font-family: ${brandFonts.primary};
    font-weight: ${fontWeights.light};
  }

  .fade {
    animation: fade;
  }

  @keyframes fade {
    from {
      opacity: 0;
      transform: translateY(40px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export default GlobalStyle;
