import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import {
  breakpointQuery,
  standardColours,
  fontWeights,
  fontSize,
} from '../../utilities';

const StyledSubnavigation = styled.ul`
  ${breakpointQuery.mlarge`
    position: absolute;
    left: -9999em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    padding: 30px;
    width: 100%;
    background-color: ${standardColours.lighterGrey};
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 2px 0;
  `}
`;

const StyledSubnavigationItem = styled.li``;

const StyledSubnavigationLink = styled(Link)`
  display: block;
  padding: 8px 30px;
  color: ${standardColours.white};
  font-weight: ${fontWeights.mlarge};
  ${fontSize(14)};

  ${breakpointQuery.small`
    ${fontSize(15)};
  `}

  ${breakpointQuery.mlarge`
    padding: 0;
    color: ${standardColours.darkerGrey};
    ${fontSize(14)};
  `}
`;

const StyledSubnavigationText = styled.span`
  display: block;
  margin-top: 8px;
  font-weight: ${fontWeights.light};
  ${fontSize(12)};
  line-height: 1.5;
`;

const Subnavigation = ({
  data: {
    allDatoCmsSolution: { nodes: solutions },
    allDatoCmsProductService: { nodes: productsServices },
  },
  url,
}) => {
  if (url === '/solutions/') {
    return (
      <StyledSubnavigation>
        {solutions.map(({ id, title, slug }) => (
          <StyledSubnavigationItem key={id}>
            <StyledSubnavigationLink to={`/solutions/${slug}/`}>
              {title}
            </StyledSubnavigationLink>
          </StyledSubnavigationItem>
        ))}
      </StyledSubnavigation>
    );
  } else if (url === '/products-services/') {
    return (
      <StyledSubnavigation>
        {productsServices.map(({ id, title, slug }) => (
          <StyledSubnavigationItem key={id}>
            <StyledSubnavigationLink to={`/products-services/${slug}/`}>
              {title}
            </StyledSubnavigationLink>
          </StyledSubnavigationItem>
        ))}
      </StyledSubnavigation>
    );
  } else if (url === '/resources/') {
    return (
      <StyledSubnavigation>
        <StyledSubnavigationItem>
          <StyledSubnavigationLink to={`/resources/insights/`}>
            News &amp; Insights
            <StyledSubnavigationText>
              The latest business trends across Finance, Procurement &amp; CSR,
              with value-added insights from us
            </StyledSubnavigationText>
          </StyledSubnavigationLink>
        </StyledSubnavigationItem>
        <StyledSubnavigationItem>
          <StyledSubnavigationLink to={`/resources/demos-webinars/`}>
            Demos &amp; Webinars
            <StyledSubnavigationText>
              Real product demonstrations and informative webinars by our MD
            </StyledSubnavigationText>
          </StyledSubnavigationLink>
        </StyledSubnavigationItem>
        <StyledSubnavigationItem>
          <StyledSubnavigationLink to={`/resources/case-studies/`}>
            Case Studies
            <StyledSubnavigationText>
              Specific challenges we've helped clients overcome which might
              resonate with you
            </StyledSubnavigationText>
          </StyledSubnavigationLink>
        </StyledSubnavigationItem>
      </StyledSubnavigation>
    );
  }
};

export default props => (
  <StaticQuery
    query={graphql`
      query SubnavigationQuery {
        allDatoCmsSolution(sort: { fields: position }) {
          nodes {
            id
            title
            slug
          }
        }
        allDatoCmsProductService(
          filter: { excludeFromNavigation: { ne: true } }
          sort: { fields: position }
        ) {
          nodes {
            id
            title
            slug
          }
        }
      }
    `}
    render={data => <Subnavigation data={data} {...props} />}
  />
);
